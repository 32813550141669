.reasons {
  padding: 0 2rem;
  display: flex;
  gap: 2rem;
}

.left-r {
  flex: 1 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 1fr;
}

.left-r > img {
  object-fit: cover;
}

.left-r > :nth-child(1) {
  width: fill;
  grid-row: 1/3;
  height: 28rem;
}

.left-r > :nth-child(2) {
  width: fill;
  height: 16rem;
  grid-column: 2/4;
}

.left-r > :nth-child(3) {
  width: stretch;
  height: 11rem;
  object-fit: cover;
  grid-column: 2/3;
  grid-row: 2;
}

.left-r > :nth-child(4) {
  width: stretch;
  grid-row: 2;
  grid-column: 3/4;
  height: 11.2rem;
}


.right-r {
  flex: 1 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.right-r>span {
  color: var(--orange);
  font-weight: bold;
}

.right-r>div {
  color: white;
  font-size: 3rem;
}

.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.details-r>div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}

.details-r>div>img {
  width: 2rem;
  height: 2rem;
}

.partners {
  display: flex;
  gap: 1rem;
}

.partners>img {
  width: 3rem;
  height: auto;
}

@media screen and (max-width: 768px) {
  .reasons {
    flex-direction: column;
  }

  .left-r {
    grid-template-columns: none;
    overflow: hidden;
  }

  .left-r > :nth-child(1) {
    justify-self: center;
    width: fit-content;
    height: 20rem;
  }

  .left-r > :nth-child(2) {
    display: none;
  }

  .left-r > :nth-child(3) {
    display: none;

  }

  .left-r > :nth-child(4) {
    display: none;
  }

}